import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { gsap } from 'gsap';

//* Helpers
import { useActiveSection } from '@/helpers';

//* Style
import GallerySectionStyle from './style';

//* Components
import Text from '@/components/common/Text';
import Video from '@/components/common/Video';
import Button from '@/components/common/Button';
import Container from '@/components/common/Container';
import ScrollActive from '@/components/common/ScrollActive';
import { withUIContext } from '@/context';

const VideoSection = ({ title, gallery, winWidth }) => {
	const isDesktop = winWidth >= 1280;

	//! Refs
	const slidesRef = useRef();
	const titleRef = useRef();
	const sectionRef = useRef();
	const videoRef = useRef();
	const cursorRef = useRef();
	const contRefsCursor = useRef();
	const mobileRefPlayPause = useRef();

	//! State
	const [activeIndex, setActiveIndex] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);
	const [showControls, setShowControls] = useState(true);

	//! Active section
	const activeSection = useActiveSection(sectionRef);

	//! Stop Prev Video-playing
	function pausePrevVideo() {
		const file = slidesRef.current;
		const video = file.querySelector(`.slide${activeIndex}`);
		video.pause();
		setIsPlaying(false);
	}

	//! Click Play Video
	function clickPlayPauseVideo() {
		const file = slidesRef.current;
		const video = file.querySelector(`.slide${activeIndex}`);
		if (!video) return;

		if (video.paused || video.ended) {
			video.play();
			setIsPlaying(true);
		} else {
			video.pause();
			setIsPlaying(false);
		}
	}

	//! Video auto-stop
	useEffect(() => {
		const file = slidesRef.current;

		const video = file.querySelector(`.slide${activeIndex}`);

		video.addEventListener('ended', function () {
			setIsPlaying(false);
			setShowControls(true);
		});
	}, [videoRef.current, activeIndex]);

	//! Show button Play
	const handleMouseEnter = () => {
		if (!isDesktop) {
			gsap.to(
				mobileRefPlayPause.current,

				{
					duration: 0.4,
					ease: 'power1.out',
					opacity: 1,
				}
			);
		}
		setShowControls(true);
	};

	const handleMouseLeave = () => {
		if ((!showControls || isPlaying) && !isDesktop) {
			gsap.to(
				mobileRefPlayPause.current,

				{
					ease: 'power1.out',

					duration: 0.4,
					opacity: 0,
				}
			);
		}
		isPlaying && setShowControls(false);
	};

	//! All Videos
	const storeAllVideos = useMemo(() => {
		return gallery?.length > 0
			? gallery.map((slide, index) => {
					const prevClass = activeIndex > index ? 'prev-slide' : '';
					const nextClass = activeIndex < index ? 'next-slide' : '';
					return (
						<Video
							autoPlay={false}
							ref={videoRef}
							key={index}
							src={slide?.src}
							alt={slide?.alt}
							className={`slide  ${activeIndex === index ? 'active' : ''} ${prevClass} ${nextClass}`}
							videoIndex={`slide${index}`}
						/>
					);
			  })
			: null;
	}, [gallery, activeIndex, isPlaying]);

	useEffect(() => {
		if (activeSection) {
			gsap.to(slidesRef.current, { scale: 1, duration: 1, ease: 'power3.easeOut' });
		}
	}, [activeSection]);

	//!cursor
	//! Handle mouse move
	const handleMouseMoveCursor = useCallback(
		(e) => {
			if (isDesktop) {
				const cursorContainer = contRefsCursor.current.getBoundingClientRect();

				const x = e.clientX - cursorContainer.left;
				const y = e.clientY - cursorContainer.top;
				const clientWidth = cursorContainer.width;
				const clientHeight = cursorContainer.height;

				if (x > clientWidth || x < 0 || y > clientHeight || y < 0) {
					gsap.to(cursorRef.current, { duration: 0.3, opacity: 0, scale: 0 });
				} else {
					gsap.to(cursorRef.current, { duration: 0.3, opacity: 1, left: x, top: y, scale: 1 });
				}
			}
		},
		[winWidth]
	);

	//! Handle mouse leave
	const handleMouseLeaveCursor = useCallback(() => {
		if (isDesktop) {
			gsap.to(cursorRef.current, { duration: 0.3, opacity: 0, scale: 0 });
		}
	}, [winWidth]);

	return gallery?.length > 0 ? (
		<GallerySectionStyle ref={sectionRef}>
			<Container>
				<div className='gallery-title-wrap'>
					<ScrollActive
						animEveryLine
						allRefs={titleRef}
						duration={0.5}
						delay={0.2}
						reverse
						delayAnim={0.1}>
						<Text
							ref={titleRef}
							className={'h1 font-montserrat-medium gallery-title opacity-0'}>
							{title}
						</Text>
					</ScrollActive>
				</div>

				<div className='slides-container'>
					<div
						className='slides-wrap'
						ref={slidesRef}
						onMouseMove={handleMouseEnter}
						onMouseLeave={handleMouseLeave}>
						{storeAllVideos}
						<div className={`wrapperCursor`}>
							<div
								className={`${isDesktop ? 'desktopCursor' : 'static-cursor'}`}
								onMouseMove={isDesktop ? handleMouseMoveCursor : null}
								onMouseLeave={isDesktop ? handleMouseLeaveCursor : null}
								ref={contRefsCursor}>
								{/* {showControls && */}
								{isDesktop ? (
									<div className='wrapperDesktopCursor'>
										<Text
											ref={cursorRef}
											onClick={clickPlayPauseVideo}
											tag={`span`}
											className={`h5 font-montserrat-medium call-to-action-drag`}>
											<Text
												tag={`span`}
												className={`h5 font-montserrat-medium `}
												text={isPlaying ? 'pause' : 'play'}
											/>
										</Text>
									</div>
								) : (
									<Text
										ref={mobileRefPlayPause}
										onClick={clickPlayPauseVideo}
										tag={`span`}
										className={`h5 font-montserrat-medium call-to-action-drag`}>
										<Text
											tag={`span`}
											className={`h5 font-montserrat-medium `}
											text={isPlaying ? 'pause' : 'play'}
										/>
									</Text>
								)}
								{/* } */}
							</div>
						</div>
					</div>

					<Button
						isFullRadius
						btnType={'frame'}
						className={'prev-button'}
						isDisabled={activeIndex === 0}
						onClick={() =>
							setActiveIndex((prev) => {
								pausePrevVideo();
								return activeIndex - 1;
							})
						}
					/>

					<Button
						isFullRadius
						btnType={'frame'}
						className={'next-button'}
						isDisabled={activeIndex === gallery.length - 1}
						onClick={() =>
							setActiveIndex((prev) => {
								pausePrevVideo();
								return activeIndex + 1;
							})
						}
					/>
				</div>
			</Container>
		</GallerySectionStyle>
	) : null;
};

export default withUIContext(VideoSection, ['winWidth']);
