import styled from 'styled-components';

const GallerySectionStyle = styled.div`
	--galleryTitleMarginBottom: var(--sp12x);
	--arrowRightAndLeftPosition: var(--sp4x);
	--circleSizes: var(--sp41x);

	margin-bottom: var(--sectionDistance);

	.gallery-title-wrap {
		overflow: hidden;
		margin-bottom: var(--galleryTitleMarginBottom);
	}

	.slides-container {
		position: relative;
		/* overflow: hidden; */

		.prev-button {
			position: absolute;
			top: 50%;
			left: var(--arrowRightAndLeftPosition);
			transform: translateY(-50%);
		}

		.next-button {
			position: absolute;
			top: 50%;
			right: var(--arrowRightAndLeftPosition);
			transform: translateY(-50%) rotate(180deg);
		}

		.slides-wrap {
			transform: scale(1.2);
			position: relative;

			.slide {
				--proportion: 49.2%;

				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				transition: 0.8s ease-out;
				pointer-events: none;

				&.next-slide {
					clip-path: inset(0% 0% 0% 100%);
					overflow: hidden;
					z-index: 2;
					transition: 0.8s ease-out;
				}

				&.prev-slide {
					clip-path: inset(0% 100% 0% 0%);
					overflow: hidden;
					z-index: 2;
					transition: 0.8s ease-out;
				}

				&.active {
					position: relative;
					pointer-events: all;
					clip-path: inset(0% 0% 0% 0%);
					transition: 0.8s ease-out;
				}
			}
		}
	}
	.wrapperCursor {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
	}
	.static-cursor {
		position: absolute;
		left: 50%;
		top: 50%;
		width: var(--circleSizes);
		height: var(--circleSizes);
		transform: translate(-50%, -50%);
	}
	.desktopCursor {
		position: relative;
		width: 80%;
		height: 100%;
		/* overflow: hidden; */
		inset: 0;
		/* display: flex;
		justify-content: center;
		align-items: center; */
		cursor: none;
		.wrapperDesktopCursor {
			width: var(--circleSizes);
			height: var(--circleSizes);
			transform: translate(-50%, -50%);
			cursor: none;
		}
		.call-to-action-drag {
			transform: scale(0);
			cursor: none;
			span {
				transform: rotate(-30deg);
			}
		}
	}
	.call-to-action-drag {
		display: flex;
		align-items: center;
		justify-content: center;
		width: var(--circleSizes);
		height: var(--circleSizes);
		border-radius: 50%;
		color: var(--black1000);
		background-color: var(--white);
		transform: rotate(-30deg);
		text-align: center;
		position: absolute;
		z-index: 100;
		cursor: pointer;
	}
	.cursor-none {
		cursor: none;
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--galleryTitleMarginBottom: var(--sp8x);
		--arrowRightAndLeftPosition: var(--sp3x);
		--circleSizes: var(--sp31x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--galleryTitleMarginBottom: var(--sp8x);
		--arrowRightAndLeftPosition: var(--sp2x);
		--circleSizes: var(--sp27x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--galleryTitleMarginBottom: var(--sp8x);
		--arrowRightAndLeftPosition: var(--sp2x);
		--circleSizes: var(--sp27x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--galleryTitleMarginBottom: var(--sp6x);
		--arrowRightAndLeftPosition: var(--sp2x);
		--circleSizes: var(--sp24x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--galleryTitleMarginBottom: var(--sp5x);
		--arrowRightAndLeftPosition: var(--sp2x);
		--circleSizes: var(--sp20x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--galleryTitleMarginBottom: var(--sp5x);
		--arrowRightAndLeftPosition: var(--sp2x);
		--circleSizes: var(--sp16x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--galleryTitleMarginBottom: var(--sp3x);
		--arrowRightAndLeftPosition: var(--sp1-5x);
		--circleSizes: var(--sp14x);
		.slides-container {
			.slides-wrap {
				.slide {
					--proportion: 73.8%;
				}
			}
		}
	}
`;

export default GallerySectionStyle;
